const productsX = [
    {
        title: "KrotakV-21 + Activation Key",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "krotakv21",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "200",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "CompressorT2X",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "compressor-t2x",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "400",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "One way Security CellWall (S-CW)",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "one-way-security",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "350",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "Two way Security CellWall (S-CW)",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "two-way-security",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "245",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "SPXV2 + Activation Key",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "spxv2-activation-key",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "400",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "V2 Patcher",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "v2-patcher",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "310",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "Server Upgrade Software",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "server-upgrade-software",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "380",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "ASP Redirect",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "asp-redirect",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "250",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "Docker Daemon",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "docker-daemon",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "355",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "Package Checker Plugin",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "package-checker",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "200",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "Server Processor",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "server-processor",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "250",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "XDG Portal",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "xdg-portal",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "390",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "XDG Processor",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "xdg-processor",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "200",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "Circuit Breakpoint Debugger",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "circuit-breakpoint-debugger",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "245",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "SCShield Processor",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "scshield-processor",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "400",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "Software Cloner",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "software-cloner",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "200",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
    {
        title: "CLion",
        tags: ["forex", "automation", "bots", "python"],
        imgUrl: "https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7",
        id: "clion",
        gallery: ["https://th.bing.com/th/id/OIP.hFTrjwd9zCZvoRGbbKFe-wHaE9?w=255&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"],
        productId: "krotakv21-activation-key",
        dateUploaded: "September 2019",
        price: "320",
        description: "...",
        descriptionX: "...",
        descriptionFull: "...",
        ratings: 4,
        downloads: 35,
    },
]

export default productsX