import React from 'react'

const NewsLetter = () => {
  return (
    <>
      <div className='newsletter'>
            <h2>Subscribe to our <span>Newsletter</span></h2>
            <p>
              Lorem ipsum dolor amet lorem ipsum dolor amet Lorem ipsum dolor amet lorem ipsum dolor amet
            </p>
            <form>
              <input type='email' placeholder='address@email.com' required />
              <button className='button'>
                Submit
              </button>
            </form>
          </div>
    </>
  )
}

export default NewsLetter