import React from 'react'
import { FaArrowRight, FaPlus} from "react-icons/fa"
import Footer from '../../components/footer/Footer'
import NewsLetter from '../../components/newsletter/NewsLetter'
import Bubble from '../../components/bubble/Bubble'
import { useNavigate } from 'react-router'
import heroImage from "../../assets/hero.png"
import { IoSparkles } from "react-icons/io5";

const Home = () => {
  const partners = [
    {
      name: "github",
      url: "https://cdn-icons-png.flaticon.com/128/25/25657.png"
    },
    {
      name: "discord",
      url: "https://cdn-icons-png.flaticon.com/128/3670/3670157.png"
    },
    {
      name: "mit",
      url: "https://cdn-icons-png.flaticon.com/128/11378/11378728.png"
    },
    {
      name: "react",
      url: "https://cdn-icons-png.flaticon.com/128/11023/11023351.png"
    },
    {
      name: "react",
      url: "https://cdn-icons-png.flaticon.com/128/5968/5968312.png"
    },
    {
      name: "fiverr",
      url: "https://th.bing.com/th/id/OIP.Arb4siojnanMD4dOpW5uHgHaHa?w=179&h=180&c=7&r=0&o=5&dpr=1.8&pid=1.7"
    },
  ]

  const navigate = useNavigate()

  return (
    <>
      <div className={"page"}>
        <div className='content home'>
          <div className='head'>
            {/* <Bubble size={"medium"} color={"primary"} /> */}
            <div className='left'>
              <IoSparkles />
              <img src={heroImage} alt='' style={{
                width: "80%",
                maxWidth: "400px",
                // marginTop: "-50px"
              }} />
            </div>
            <div className='ii'>
              <div className='left'>
                <h1>
                  <span>DMS</span> Softwares
                </h1>
              </div>
              <div className='right'>
                <p style={{maxWidth: "400px"}}>
                  Premium provider of a collection of high-value softwares for consumer and developer use.
                </p>
                <button className='button' onClick={()=>{
                  navigate("/store")
                }}>
                  Visit Store <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
          <div className='store-preview'>
            <div className='assets-container'>
              <div className='asset'>
                <div className='asset-img'></div>
                <div className='asset-details'>
                  Lorem Ipsum Dolor Amet
                </div>
                <div className='asset-price'>
                  $100
                </div>
              </div>
              <div className='asset'>
                <div className='asset-img'></div>
                <div className='asset-details'>
                  Lorem Ipsum Dolor Amet
                </div>
                <div className='asset-price'>
                  $100
                </div>
              </div>
            </div>
            {/* <div className='btn-holder center'>
              <button className='button outline white'>
                View More <FaArrowRight />
              </button>
            </div> */}
          </div>
          <div className='success-in-numbers' style={{
            position: "relative",
          }}>
            <Bubble color={"secondary"} />
            <h2>Our <span>Impact</span> in Numbers</h2>
            <div className='count-holder'>
              <div className='count'>
                  <h1>270 <FaPlus /></h1>
                  <p>Users</p>
              </div>
              <div className='count'>
                  <h1>53 <FaPlus /></h1>
                  <p>Devlopers</p>
              </div>
              <div className='count'>
                  <h1>110 <FaPlus /></h1>
                  <p>Users</p>
              </div>
            </div>
            <div className='button-holder'>
              <button className='button outline white' onClick={()=>{
                navigate("/store")
              }}>
                Make a Purchase <FaArrowRight />
              </button>
            </div>
          </div>
          <div className='partners'>
            <h2>Our Partners</h2>
            <div className='partners-holder'>
              {partners.map((partner, index)=>{
                return (
                  <div className='partner' style={{
                backgroundImage: `url(${partner.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }} key={index}></div>
                )
              })}
            </div>
          </div>
          <NewsLetter />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Home