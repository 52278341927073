import React from 'react'

const Bubble = ({size, color}) => {
  return (
    <>
        <div className={`bubble ${size} ${color}`}></div>
    </>
  )
}

export default Bubble