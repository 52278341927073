import React, { useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import Home from './pages/home/Home'
import Store from './pages/store/Store'
import Header from './components/header/Header'
import Menu from './components/Menu/Menu'
import AppContext from './appContext/appContext'
import "./styles/index.scss"
import Search from './pages/search/Search'
import Product from './pages/product/Product'
import Web3 from 'web3';
import productsX from './assets/products'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const App = () => {
  const initialState = {
    isLoggedIn: false,
    token: "",
    exchangeRate: 1000,
    user:{
      userName: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      imgUrl: "",
      isAdmin: false,
      userId: "",
      purchased: []
    },
    allUsers:[],
    Store: [],
    isLoading: false,
  }

  const navigate = useNavigate()

  const [ products, setProducts ] = useState([])
  useEffect(()=>{
    if(productsX?.length){
      setProducts(productsX)
    }
  }, [])

  const updateAppState = (state, action)=>{
    const actionType = action.type
    const data = action.data

    switch (actionType){
      case ("logged-in"):
        const userData = data
        state.functions.handleNotification("Login Successful", "success")
        localStorage.setItem("token", userData.token)
        localStorage.setItem("userId", userData.userId)
        return {
          ...state,
          isLoggedIn: true,
          isChecking: false,
          isCheckingPlan: true,
          token: userData.token,
          user: {
          ...state.user,
          }
        }

      case ("loaded"):
        return{
          ...state,
          isLoading: false,
        }

      case ("got-current-user"):
      const user = data.user
      console.log(user)
      const token = localStorage.getItem("token")
      
      return {
        ...state,
        isLoggedIn: true,
        token: token,
        isChecking: false,
        isCheckingPlan: true,
        user: {
          ...state.user,
        }
      }

      case ("got-users"):
        const allUsers = data
        return {
          ...state,
          allUsers: [
            ...allUsers
          ]
        }

      case ("navigate"):
        const path = data
        navigate(path)
        return {
          ...state,
        }

      default:
        return state
      
    }
  }

  const [web3, setWeb3] = useState(null)
  const [accounts, setAccounts] = useState([])

  const [appState, dispatch] = useReducer(updateAppState, initialState)

  const [hasScrolled, setHasScrolled] = useState(true)

  const location = useLocation()

  const intervalRef = useRef()

  const loadWeb3 = async () => {
    console.log("Try connect")
    if (window.ethereum) {
      const newWeb3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.enable();
        setWeb3(newWeb3);
        const newAccounts = await newWeb3.eth.getAccounts();
        setAccounts(newAccounts);
      } catch (error) {
        console.error('Error connecting to wallet:', error);
      }
    } else if (window.web3) {
      // Legacy dapp browsers
      const newWeb3 = new Web3(window.web3.currentProvider);
      try{
        setWeb3(newWeb3);
        const newAccounts = await newWeb3.eth.getAccounts();
        setAccounts(newAccounts);
        console.log(newWeb3)
      } catch(error){
        console.error(error)
      }
    } else {
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  };

  const handlePayment = async () => {
    const account = accounts[0]
    if (!web3 || !account) {
      console.error('Metamask not connected.');
      return;
    }

    // Replace 'your_wallet_address' with your actual wallet address
    const walletAddress = '0x56F20B33097Bf12326111418dA8C635a8bc8EDBd';

    // Convert USD to Wei
    const amountInUSD = 200;
    const amountInWei = web3.utils.toWei(amountInUSD.toString(), 'ether');

    const gasPrice = web3.utils.toWei('30', 'gwei');

    try {
      // Send transaction with adjusted gas price
      const transactionHash = await web3.eth.sendTransaction({
        from: account,
        to: walletAddress,
        value: amountInWei,
        gasPrice: gasPrice,
      });
  
      console.log(`Transaction successful. Transaction hash: ${transactionHash}`);
  
      // You might want to wait for transaction confirmation here
      // and then log "Payment successful" when confirmed.
  
      // For simplicity, I'm logging "Payment successful" immediately.
      console.log('Payment successful');
      return true
    } catch (error) {
      console.error('Payment failed:', error);
      return false
    }
  };

  useLayoutEffect(()=>{
    setHasScrolled(false)
    setShowMenu(false)
    clearInterval(intervalRef.current)
    const page = document.querySelector(".page")
    if(page){
      console.clear()
      
      page.addEventListener("scroll", ()=>{
        const pageScroll = page.scrollTop

        if(pageScroll){
          if(pageScroll < 50){
            setHasScrolled(false)
          } else{
            setHasScrolled(true)
          }
        }
      })
      page.addEventListener("touch", ()=>{
        const pageScroll = page.scrollTop

        if(pageScroll){
          if(pageScroll < 50){
            setHasScrolled(false)
          } else{
            setHasScrolled(true)
          }
        }
      })

      intervalRef.current = setInterval(()=>{
        const pageScroll = page.scrollTop
  
        if(pageScroll){
          if(pageScroll < 50){
            setHasScrolled(false)
          } else{
            setHasScrolled(true)
          }
        }
      },[500])
    }

  },[location.pathname])

  useEffect(()=>{
    setTimeout(()=>{
      dispatch({type: "loaded"})
    }, 500)
  },[])

  const [showPreloader, setShowPreloader] = useState(false)

  const timeOutRef = useRef()

  useEffect(()=>{
    clearTimeout(timeOutRef.current)
    timeOutRef.current = setTimeout(()=>{
      setShowPreloader(false)
    },2500)
  },[])

  const [showMenu, setShowMenu] = useState(false)

  const stripePromise = loadStripe('your-publishable-key');

  return (
    <Elements stripe={stripePromise}>
      <AppContext.Provider value={appState}>
        {showPreloader && <div className='preloader'>
          <div className='spinner'></div>
        </div>}
        <div className={`app ${showMenu ? "show-menu" : ""} ${hasScrolled ? "has-scrolled" : ""}`}>
          <Header toggleMenu={()=>{
            setShowMenu(!showMenu)
          }} closeMenu={()=>{
            setShowMenu(false)
          }} />
          <Menu loadWeb3={loadWeb3} accounts={accounts} web3={web3} closeMenu={()=>{
            setShowMenu(false)
          }} />

          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/store' element={<Store products={products} />} />
            <Route path='/search' element={<Search products={products} />} />
            <Route path='/product/:productId' element={<Product handlePayment={handlePayment} loadWeb3={loadWeb3} accounts={accounts} products={products} />} />
            <Route path='/*' element={<Home />} />
          </Routes>
        </div>
      </AppContext.Provider>
    </Elements>
  )
}

export default App