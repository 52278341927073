import React from 'react'
import { FaArrowRight } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
import NewsLetter from '../../components/newsletter/NewsLetter'

const Store = ({products}) => {
  const productsTop = products?.slice(0, 3)
  const navigate = useNavigate()
  return (
    <>
      <div className={"page"}>
        <div className='content home'>
          <div className='head'>
            <div className='left'>
              <h2>
                Store
              </h2>
            </div>
            <div className='right'>
              {productsTop?.length ? <>
                <p>
                  Browse our quality collection of premium utility softwares. 
                </p>
                <button className='button' onClick={()=>{
                  navigate("/search")
                }}>
                  View all Products <FaArrowRight />
                </button>
              </> : <>
                <p>
                  There are no products for sale at the moment.
                </p>
              </>}
            </div>
          </div>
          {productsTop?.length ? <div className='trending-products'>
            <h3>
              Top Selling ⚡
            </h3>
            <div className='wrapper'>
              <div className='holder'>
                {productsTop.map((product, index)=>{
                  return (
                    <div className='product' key={product.index}>
                      <div className='image' style={{
                        backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.3333333333), rgba(0, 0, 0, 0.8)), url(${product.imgUrl})`
                      }}></div>
                      <div className='price'>${product.price}</div>
                      <div className='title'>
                        {product.title}
                      </div>
                      <button className='button outline white' onClick={()=>{
                        navigate(`/product/${product.id}`)
                      }}>
                        <FaArrowRight />
                      </button>
                    </div>
                  )
                })}
              </div>
            </div>
          </div> : <></>}
          <NewsLetter />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Store