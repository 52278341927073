import React from 'react'
import QRCode from 'react-qr-code'
import { Link } from 'react-router-dom'
import { BiSolidShareAlt } from 'react-icons/bi'

const Footer = () => {
  const url = window.location.href

  const handleShare = async (urlToShare, text) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          text: text,
          url: urlToShare,
        });
      } else {
        throw new Error('Web Share API not supported in this browser.');
      }
    } catch (error) {
      console.error('Sharing failed:', error);
    }
  };


  return (
    <>
      <div className='footer'>
        <div className='holder'>
          <div className='sitemap'>
            <Link className='logo' to={"/"}>
              <div className='logo-img'>
                DMS
              </div>
            </Link>
            <Link to={"/"}>
              Home
            </Link>
            <Link to={"/store"}>
              Store
            </Link>
            <Link to={"/search"}>
              Search
            </Link>
            <Link to={"/faqs"}>
              Faqs
            </Link>
            <Link to={"/faqs"}>
              Customer Support
            </Link>
          </div>
          <div className='qr-code'>
            <QRCode value={url} />
            <button className='button white' onClick={()=>{
              handleShare(url, "Visit DMS Softwares today!!")
            }}>
              Share <BiSolidShareAlt />
            </button>
          </div>
          <div className='cprt'>
            © 2023 DMS Solutions
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer