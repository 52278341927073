import React, { useEffect, useRef, useState } from 'react'
import Footer from '../../components/footer/Footer'
import NewsLetter from '../../components/newsletter/NewsLetter'
import { FaStar, FaArrowLeft, FaRegTimesCircle, FaInfo, FaChevronDown, FaRegCheckCircle, FaTimes } from 'react-icons/fa'; // Replace with your preferred star icon from react-icons
import { PaystackButton } from 'react-paystack';
import { useParams } from 'react-router';
import StripeCheckout from 'react-stripe-checkout';


// const galleryImages = ['https://randompicturegenerator.com/img/picture-generator/54e0dd4a4357a514f1dc8460962e33791c3ad6e04e5074417c2d78d1914fc0_640.jpg', 'https://randompicturegenerator.com/img/picture-generator/55e9dd4b4a57a414f1dc8460962e33791c3ad6e04e507440762e79d7944ecd_640.jpg', 'https://randompicturegenerator.com/img/picture-generator/55e5d5434b55ad14f1dc8460962e33791c3ad6e04e507440762a7cd69449c4_640.jpg']; // Replace with actual image URLs
// const initialRating = 4; // Initial product rating
// const initialDownloads = 1000; // Initial download count

const Product = ({products, handlePayment, accounts, loadWeb3}) => {
  // const tags = ["Web Design", "AI", "3D design",]
  const publishableKey = "pk_test_51OIDYvI22gMr5Ui42PCSCXKlgN9n185njhVOrvLTQFQK7GuNVhuNFdDJk2stiC8RsyeSmExRWK07EEv4nv077UdS00rw9y3vad"

  const [isConnected, setIsConnected] = useState(false)
  const [errorWhileLoading, setErrorWhileLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [isPayingStripe, setIsPayingStripe] = useState(false)
  const params = useParams()
  const parsedId = params.productId
  useEffect(()=>{
    const userAcct = accounts[0]
    if(userAcct){
      setIsConnected(true)
    } else{
      setIsConnected(false)
    }
  },[accounts])

  // const rate = process.env.RATE
  const rate = 900

  const [thisProduct, setThisProduct] = useState({
    title: `...`,
    description: `...`,
    gallery: [],
    productId: "...",
    dateUploaded: "...",
    price: "...",
    descriptionX: `...`,
    descriptionFull: `...`
  })

  const [productState, setProductState] = useState('PAID');

  const parsedPrice = parseInt(thisProduct.price)
  const toNaira = parsedPrice * rate

  const [uiState, setUiState] = useState({
    startedPayment: false,
    hasPaid: false,
    paying: false,
  })

  const closePayment = () =>{
    setUiState({
      startedPayment: false,
      paying: false,
      hasPaid: false,
    })
  }

  const publicKey = "pk_test_9af5a149e78c0ec3d252b3c20abfc25a05f5819f"
  const amount = toNaira ? (toNaira * 100) : 0
  const [isConnecting, setIsConnecting] = useState(false)
  const [isPaying, setIsPaying] = useState(false)
  const [email, setEmail] = useState("")
  const [confirmEmail, setConfirmEmail] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
      email,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      alert("Thanks for dashing me free money! Come back soon!!")
      localStorage.setItem("email", email)
      setUiState((prev)=>{
        return ({
          ...prev,
          startedPayment: false,
          hasPaid: false,
          paying: false,
        })
      })
    },
    onClose: () => alert("Wait! Don't leave :(  I need your money"),
  }

  const stripeInitialized = ()=>{
    setIsPayingStripe(true)
  }

  useEffect(()=>{    
    setProductState((prev)=>{
      return prev
    })

  },[])

  useEffect(()=>{
    setHasLoaded(false)
    const foundProducts = products
    if(foundProducts?.length){
      try{
        const filteredProducts = foundProducts.filter((product)=>{
          return product.id === parsedId
        })
        if(filteredProducts?.length){
          const filteredProduct = filteredProducts[0]
          if(filteredProduct){
            setThisProduct(filteredProduct)
            setHasLoaded(true)
          } else{
            setNotFound(true)
            setHasLoaded(true)
          }
        } else{
          setNotFound(true)
          setHasLoaded(true)
        }
      } catch(error){
        console.error(error)
        setErrorWhileLoading(true)
        setNotFound(true)
        setHasLoaded(true)
      }
    } else{
      setNotFound()
      setHasLoaded(true)
    }
  }, [products, parsedId])

  const purchase = ()=>{
    if(productState === "FREE"){
      alert("Product is not available for Download at the moment...")
    } else{
      setUiState((prev)=>{
        return ({
          ...prev,
          startedPayment: true,
        })
      })
    }
  }

  const btnRef = useRef()

  const initializeCryptoTransaction = async ()=>{
    console.clear()
    console.log("Initialize Crypto")
    setIsPaying(true)
    // handlePayment()
    const res = await handlePayment()
    if(res){
      setIsPaying(false)
      alert("Payment Successful")
    } else{
      setIsPaying(false)
      alert("An Error Occured")
    }
  }

  const connectWallet = async ()=>{
    console.clear()
    console.log("Connect Wallet")
    setIsConnecting(true)
    loadWeb3()
  }

  const makePurchase = ()=>{
    if(name && email && phone && confirmEmail){
      if(email === confirmEmail){
        try{
          setUiState((prev)=>{
            return ({
              ...prev,
              paying: true,
            })
          })
        } catch(error){
          alert("An Error Occured")
        }
      } else{
        alert("Emails do not match")
      }
    } else{
      alert("Please fill all the fields to proceed")
    }
  }

  const [readAll, setReadAll] = useState(false)

  const onToken = (token) => {
    console.log(token);
    setIsPayingStripe(false)
    setUiState({
      startedPayment: true,
      hasPaid: true,
      paying: false,
    })
    console.log("Payment Successful")
  };

  const parsedPriceX = thisProduct.price ? Number(thisProduct.price) : null
  const inCents = parsedPriceX ? parsedPriceX * 100 : null

  return (
    <>
      {uiState.startedPayment && <div className='payment'>
        <div className='backdropx'></div>
        {uiState.hasPaid ? <>
          {uiState.hasPaid && <div className='card'>
          <div className='amount button outline'>
            ${thisProduct?.price ? thisProduct.price : "..."}
          </div>
          {!(isPaying || isConnecting) && <div className='close' onClick={()=>{
            setUiState((prev)=>{
              return ({
                ...prev,
                paying: false,
                hasPaid: true,
                startedPayment: false
              })
            })
          }}>
            <FaTimes /> Done
          </div>}
          <h2 style={{
            textAlign: "center",
          }}>Payment Successful</h2>
          <h3>
            <span>
              <FaRegCheckCircle size={70} />
            </span>
          </h3>
          <h3>
            <p style={{
              fontSize: "15px",
            }}>
              <i>Product will be delivered to your <span>Email Address</span> in a few hours</i>
            </p>
          </h3>
        </div>}
        </> : <>
          {!uiState.paying && <div className='card'>
            <div className='close' onClick={closePayment}>
              <FaRegTimesCircle size={23} />
            </div>
            <h3>Enter your Details to Continue <i style={{
              fontSize: "14px",
              fontWeight: "400",
            }}>(required)</i> </h3>
            <form onSubmit={(e)=>{
              e.preventDefault()
              makePurchase()
            }}>
              <input required placeholder='Full Name' type='text' name='name' value={name} onChange={(e)=>{
                setName(e.target.value)
              }} />
              <input required placeholder='Phone Number' type='tel' name='phone' value={phone} onChange={(e)=>{
                setPhone(e.target.value)
              }} />
              <input required placeholder='Email Address' name='name' type='email' value={email} onChange={(e)=>{
                setEmail(e.target.value)
              }} />
              <input required placeholder='Confirm Email Address' name='name' type='email' value={confirmEmail} onChange={(e)=>{
                setConfirmEmail(e.target.value)
              }} />
              <button className='button'>Done</button>
            </form>
          </div>}
          {uiState.paying && <div className='card'>
            <div className='amount button outline'>
              ${thisProduct?.price ? thisProduct.price : "..."}
            </div>
            {!(isPaying || isConnecting) && <div className='close' onClick={()=>{
              if(isPayingStripe){
                setIsPayingStripe(false)
              } else{
                setUiState((prev)=>{
                  return ({
                    ...prev,
                    paying: false,
                  })
                })
              }
            }}>
              <FaArrowLeft />
            </div>}
            <h3>The Product will be delivered in your <span onClick={()=>{
              setUiState((prev)=>{
                return ({
                  ...prev,
                  paying: false,
                })
              })
            }}>Email Address</span></h3>
            {isPayingStripe ? <>
              <div className='center' style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                marginTop: "30px",
                marginBottom: "30px"
              }}>
                <div className='spinner'  style={{
                  scale: "0.8",
                }}></div>
              </div>
            </> : <>
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexWrap: "wrap"
              }}>
                <>
                {thisProduct.price && <StripeCheckout
                  token={onToken}
                  stripeKey={publishableKey}
                  name={"DMS Softwares"}
                  description={thisProduct.title}
                  panelLabel='Pay Now'
                  amount={inCents} // Amount in cents
                  currency="USD"
                  email= {email}
                  alipay // accept Alipay (default false)
                  bitcoin // accept Bitcoins (default false)
                  closed={()=>{
                    setIsPayingStripe(false)
                  }}
                >
                  {<button className='button no-anim' onClick={stripeInitialized}>
                    Pay with Fiat <i style={{fontWeight: "400", fontSize: "14px"}}>(stripe)</i>
                  </button>}
                </StripeCheckout>}
                </>
                {!isConnected ? <>
                  {phone === "129utygfhaxjhjqghji3ouyegqihgxhgjjjaxgjkka" && <PaystackButton ref={btnRef} {...componentProps} className='button' />}
                  {isConnecting ? <button className="button"  style={{opacity: "0.5"}}>
                    Connecting...
                  </button> : <button style={{
                    opacity: "0.4",
                    cursor: "not-allowed"
                  }} className="button" onClick={()=>{
                    if(phone === "xaxaxaxaxaxaxxaxax"){
                      connectWallet()
                    } else{
                      alert("Crypto Payments are coming soon")
                    }
                  }}>
                    Pay with Crypto
                  </button>}
                </> : <>
                  {isPaying ? <button className="button" style={{opacity: "0.5"}}>
                    Please wait...
                  </button> : <button className="button" onClick={()=>{
                    initializeCryptoTransaction()
                  }}>
                    Pay Now
                  </button>}
                </>}
              </div>
            </>}
          </div>}
        </>}
        
      </div>}

      {!hasLoaded ? <>
        <div className='page search'>
            <div className='content home product-page'>
              <div className='center' style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                marginTop: "50px"
              }}>
                <div className='spinner'></div>
              </div>
            </div>
        </div>
      </> : <>
        {errorWhileLoading ? <>
          <div className='page search'>
            <div className='content home product-page'>
              <h2 style={{
                fontWeight: "700",
                textAlign: "center",
                marginTop: "40px",
                fontSize: "30px"
              }}>
                An Error Occured. Please contact <a href='mailto:secondsonofadebayo@gmail.com' target="_blank" rel="noreferrer" style={{
                  textDecoration: "none",
                }}>
                  <span>support</span>
                </a>
              </h2>
            </div>
          </div>
        </> : <>
        {notFound ? <>
          <div className='page search'>
            <div className='content home product-page'>
              <h2 style={{
                fontWeight: "700",
                textAlign: "center",
                marginTop: "40px",
                fontSize: "30px"
              }}>
                Didn't Find <span>{`${parsedId}`}</span> in our database
              </h2>
            </div>
          </div>
        </> : <div className={"page search"}>
          <div className='content home product-page'>
            {thisProduct.gallery?.length ? <div className='product-thumbnail' style={{backgroundImage: `url(${thisProduct.gallery[0]})`}}></div> : <></>}
            <h2 className='title' style={{display: "flex"}}>
              {thisProduct.title} <p style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: "600",
              }}>
              Price ~
              <a href={`https://www.bing.com/search?q=convert+%24${thisProduct?.price ? thisProduct.price : "..."}+to+my+currency&cvid=21de648144d64302b49dd7ca2d4febad&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRhA0gEJMTAwMzFqMGo5qAIAsAIA&FORM=ANAB01&PC=LCTS`} target="_blank" rel="noreferrer" className='button outline' style={{marginLeft: "20px", padding: "5px 0px", border: "none", borderBottom: "4px solid", pointerEvents: "none", borderRadius: "0px"}}>${thisProduct?.price ? thisProduct.price : "..."}</a>
              </p>
            </h2>

            {thisProduct.description && <div class="product-description">
              <div className='description'>
                <FaInfo />
                <h2 dangerouslySetInnerHTML={{__html: thisProduct.description}}></h2>
              </div>
              <div className="gallery">
                <p>Gallery</p>
                <div className="gallery-images">
                  {thisProduct.gallery?.map((url, index) => (
                    <img key={index} src={url} alt="" />
                  ))}
                </div>
              </div>
              {readAll ? <div id='desc' dangerouslySetInnerHTML={{__html: thisProduct.descriptionFull}}>
              </div> : <div id='desc' dangerouslySetInnerHTML={{__html: thisProduct.descriptionX}}>
              </div>}
              <button className={readAll ? "read-all btn" : "btn"} onClick={()=>{
                setReadAll(!readAll)
                document.getElementById("desc").scrollIntoView()
              }}>
                {readAll ? "Read Less" : "Read More"} <FaChevronDown />
              </button>
            </div>}

            {thisProduct?.tags && <div className='tags'>
              {
                thisProduct?.tags.map((tag, index)=>{
                  return (
                    <span key={index}>{tag}</span>
                  )
                })
              }
            </div>}


            <div className="rating-section">
              {thisProduct?.ratings && <div className="stars">
                {Array.from({ length: 5 }).map((_, index) => (
                  <FaStar
                    key={index}
                    color={index < thisProduct.ratings ? 'gold' : 'gray'}
                    size={24}
                  />
                ))}
              </div>}
              <span className="downloads"> <b>{thisProduct?.downloads ? thisProduct.downloads : "..."}</b> Downloads</span>
            </div>

            <div className="cta-section">
              <button className='button' onClick={purchase}>
                {productState === 'FREE' ? 'Download for FREE' : `Purchase for $${thisProduct?.price ? thisProduct.price : "..."}`}
              </button>
            </div>
            <NewsLetter />
            <Footer />
          </div>
        </div>}
        </>}
      </>}
    </>
  )
}

export default Product