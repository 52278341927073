import React, { useRef, useState } from 'react'
import { FaArrowRight, FaSearch} from "react-icons/fa"
import Footer from '../../components/footer/Footer'
import NewsLetter from '../../components/newsletter/NewsLetter'
import Bubble from '../../components/bubble/Bubble'
import { useNavigate } from 'react-router'

const Search = ({products}) => {
  const [search, setSearch] = useState("")

  const handleChange=(e)=>{
    setSearch(e.target.value)
  }

  const input = useRef(null)
  const formBtn = useRef(null)

  const handleSubmit = (e)=>{
    e.preventDefault()
    input.current.blur()
    formBtn.current.blur()
  }

  const tags = ["Web Design", "AI", "3D design", "Graphics", "Automation", "Gaming"]

  const visibleProducts = products.filter((product)=>{
    const formattedSearch = search.toLowerCase()
    const formattedTitle = product.title.toLowerCase()
    return formattedTitle.includes(formattedSearch) || product.tags.includes(formattedSearch)
  })

  const handleTag = (tag)=>{
    setSearch(tag)
  }

  const navigate = useNavigate()

  return (
    <>
      <div className={"page search"}>
        <div className='content home'>
          <div className='head'>
            <Bubble size={"medium"} color={"primary"} />
            <h2>
                Search
            </h2>
            <p>
            Search for tools, assets, API keys, elemenents... available on the DMS store
            </p>
            <form onSubmit={handleSubmit}>
            <input type='search' value={search} onChange={handleChange} ref={input} minLength={3} placeholder='Search for a Tool' />
            <button ref={formBtn} className='search-btn'>
                <FaSearch />
            </button>
            </form>
            <div className='categories'>
                {tags.map((tag, index)=>{
                    return (
                        <button key={index} className='quick' onClick={()=>{
                            handleTag(tag)
                        }}>
                            {tag}
                        </button>
                    )
                })}
            </div>
          </div>
          {visibleProducts.length ? <div className='products-holder'>
            
            {
                visibleProducts.map((product, index)=>{
                    const visibleTags = product.tags.slice(0, 3)
                    const formattedTitle = product.title.slice(0, 15)
                    return (
                        <div className='product' key={index}>
                            <div className='thumbnail' style={{
                                backgroundImage: `url(${product.imgUrl})`
                            }}></div>
                            <div className='details'>
                                <p>{product.title.length > 20 ? `${formattedTitle}...` : product.title }</p>
                                <div className='tags'>
                                    {visibleTags.map((tag, index)=>{
                                        return (
                                            <span key={index}>
                                                {tag}
                                            </span>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='actions'>
                                <button onClick={()=>{
                                    navigate(`/product/${product.id}`)
                                }}>
                                    {/* <span>$100</span> */}
                                    <FaArrowRight />
                                </button>
                            </div>
                        </div>
                    )
                })
            }
          </div> : <div style={{
            marginTop: "-100px"
          }} className='center'>
            <p>
                Didn't find that. Refine your search
            </p>
          </div>}
          <NewsLetter />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Search