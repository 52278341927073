import React from 'react'
import logo from "../../assets/logo.png"
import { NavLink, useNavigate } from 'react-router-dom'
import { FaSearch } from 'react-icons/fa'

const Header = ({toggleMenu}) => {
  const navigate = useNavigate()
  return (
    <div className='header'>
      <div className='content'>
          <div className='logo' style={{cursor:"pointer"}} onClick={()=>{
            navigate("/")
          }}>
            <img src={logo} alt='Logo' />
          </div>
          <div className='links-holder'>
            <NavLink to={"/"}>
              Home
            </NavLink>
            <NavLink to={"/store"}>
              Store
            </NavLink>
            <NavLink to={"/faqs"}>
              FAQs
            </NavLink>
          </div>
          <div className='auth-holder'>
            <button className='button outline search-btn' onClick={()=>{
              navigate("/search")
            }}>
              Search <FaSearch style={{
                transform: "none"
              }} />
            </button>
            <div className='hamburger' onClick={toggleMenu}>
              <div className='bar'></div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Header