import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const Menu = ({accounts, isConnecting, web3, closeMenu, loadWeb3}) => {
  const [isConnected, setIsConnected] = useState(false)
  useEffect(()=>{
    const userAcct = accounts[0]
    if(userAcct){
      setIsConnected(true)
    } else{
      setIsConnected(false)
    }
  },[accounts])
  return (
    <div className='menu'>
      <div className='links-holder'>
        <NavLink onClick={closeMenu} to={"/"}>
          Home
        </NavLink>
        <NavLink onClick={closeMenu} to={"/store"}>
          Store
        </NavLink>
        <NavLink onClick={closeMenu} to={"/search"}>
          Search
        </NavLink>
        <NavLink onClick={closeMenu} to={"/faqs"}>
          FAQs
        </NavLink>
      </div>
      <div className='btn-holder' style={{
        flexDirection: "column",
        gap: "20px",
        textAlign: "center",
      }}>
        {!isConnected ? <button className='button' onClick={loadWeb3}>
          Connect Wallet
        </button> : <button className='button outline' style={{
          display: "flex", flexDirection: "row", pointerEvents: "none"
        }}>
          Connected: <span style={{fontSize: "13px"}}>{accounts?.length ? `${accounts[0].slice(0, 7)}...${accounts[0].slice(-4, -1)}` : "..."}</span>
        </button>}
        <p style={{
          color: "#999",
          fontSize: "15px",
        }}>
          Powered by <span style={{color: "#000", fontWeight: "500",}}>Web3</span>
        </p>
      </div>
    </div>
  )
}

export default Menu